import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Link } from "gatsby"

const Header = () => {
  const [scroll, setScroll] = useState(false)
  const [activeSection, setActiveSection] = useState("inicio")

  useEffect(() => {
    if (window.scrollY > 70) {
      setScroll(true)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 70) {
        setScroll(true)
      } else {
        setScroll(false)
      }
    })
  })

  function menuClick(option) {
    setActiveSection(option)

    if (window.location.pathname !== "/") {
      // Is not the home page
      window.location.href = `/#${option}`
      return
    }

    // Is home page, move to the section
    scrollTo(`#${option}`)
  }

  return (
    <HeaderContainer className={scroll ? "is-sticky" : ""}>
      <HeaderContent>
        <Link to="/">
          <StaticImage
            src="../assets/images/logo.png"
            alt="Asesorias CAMI"
            layout="fixed"
            placeholder="tracedSVG"
            height={65}
          />
        </Link>
        <Menu>
          <ul>
            <li className={activeSection === "inicio" ? "active" : ""}>
              <button onClick={() => menuClick("inicio")}>Inicio</button>
            </li>
            <li
              className={activeSection === "oferta-educativa" ? "active" : ""}
            >
              <button onClick={() => menuClick("oferta-educativa")}>
                Oferta Educativa
              </button>
            </li>
            <li className={activeSection === "contacto" ? "active" : ""}>
              <button onClick={() => menuClick("contacto")}>Contacto</button>
            </li>
          </ul>
        </Menu>
      </HeaderContent>
    </HeaderContainer>
  )
}

export default Header

const HeaderContainer = styled.header`
  display: flex;
  width: 100%;
  justify-content: center;
  color: white;
  margin-top: 80px;
  z-index: 2;
  position: sticky;
  top: 0;
  transition: 0.5s all ease-in-out;

  &.is-sticky {
    background-color: #060f52ad;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

const HeaderContent = styled.section`
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;

  @media screen and (min-width: 1200px) {
    padding: 0;
  }
`

const Menu = styled.nav`
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }

  ul {
    list-style: none;
    display: flex;

    li {
      padding: 5px 15px;
      font-family: "Montserrat";
      font-weight: 500;
      font-style: normal;

      button {
        color: white;
        background-color: transparent;
        border: 0;
        font-family: "Montserrat";
        font-weight: 500;
        font-style: normal;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          color: #e9dd50;
        }
      }

      &.active button {
        color: #e9dd50;
      }
    }
  }
`
