import { createGlobalStyle } from "styled-components"

import "../../assets/fonts/Montserrat/stylesheet.css"

export const GlobalStyle = createGlobalStyle`
  * {    
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Monserrat';
    font-weight: 500;
    font-style: normal;
    background-color: #E3E4E5;
    overflow-x: hidden;
  }
`
